import { Flex, Icon, Image, Text } from '@chakra-ui/react'
import { ArrowRight } from 'lucide-react'
import RouterLink from 'next/link'

const NavigationListLink = ({ title, href, colorTheme = 'gray', icon, description }: Props) => {
  return (
    <RouterLink href={href} passHref legacyBehavior>
      <Flex
        as={'a'}
        backgroundColor={`${colorTheme}.100`}
        gap={6}
        p={[3, 6]}
        height={'100%'}
        justifyContent={'space-between'}
        borderRadius={'xl'}
        sx={{
          transition: 'all 0.2s ease-in-out',
        }}
        _hover={{
          backgroundColor: `${colorTheme}.200`,
        }}
      >
        <Flex flexDirection="column" gap={2}>
          {icon && <Image alt="" src={icon} width={[4, 6]} height={[4, 6]} />}
          <Text fontWeight={'medium'} fontSize={['md', 'xl']} mt={1}>
            {title}
          </Text>
          <Text noOfLines={3} fontSize={['sm', 'md']}>
            {description}
          </Text>
        </Flex>
        <Flex flex={0}>
          <Icon as={ArrowRight} w={6} h={6} color={`${colorTheme}.400`}></Icon>
        </Flex>
      </Flex>
    </RouterLink>
  )
}

type Props = {
  icon?: string
  href?: string
  title?: string
  description?: string
  colorTheme?: 'gray' | 'orange' | 'blue' | 'green' | 'red'
}

export default NavigationListLink
