import { Box, Flex, LinkBox, LinkOverlay, Text } from '@chakra-ui/react'
import ArticleCard from 'components/ArticleCard'
import BlocksPageBuilder from 'components/BlocksPageBuilder'
import GridContainer from 'components/GridContainer'
import GridItem from 'components/GridItem'
import HeadingWithColor from 'components/HeadingWithColor'
import NavigationListLink from 'components/NavigationListLink'
import Image from 'next/image'
import constants from 'utils/constants'
import { getSiteHandle } from 'utils/i18nUtils'
import Date from '../components/Date'
import BaseLayout from '../layouts/BaseLayout'
import GET_FRONT_PAGE from '../queries/frontPage'
import { ContactInfo, Entry, FooterMenu, FrontPage as FrontPageType, MainMenu } from '../types'
import { fetcher } from '../utils/cmsDataFetcher'
import RouterLink from 'next/link'

const FrontPage = ({ data }: Props) => {
  return (
    <BaseLayout
      title="Norges arktiske studentsamskipnad"
      footerMenu={data?.footerMenu}
      mainMenu={data?.mainMenu}
      alerts={data?.alerts}
      contactInfo={data?.contactInfo}
      {...data?.entry?.seomatic}
    >
      <Box position="relative" height={['40vh', '60vh']} width={'100%'}>
        <Image
          src={data.entry?.mainImage[0]?.path}
          alt={data.entry?.mainImage[0]?.title}
          fill
          placeholder="blur"
          blurDataURL={data.entry?.mainImage[0]?.imageOptimize?.placeholderImage}
          style={{ objectFit: 'cover' }}
          priority
          sizes="100vw"
        ></Image>
      </Box>
      <GridContainer mt={[8, 16]}>
        <GridItem colSpan={[12, 9]} colStart={1}>
          <HeadingWithColor>{data.entry?.headingWithColor}</HeadingWithColor>
        </GridItem>
      </GridContainer>
      <GridContainer rowGap={[4, 8]} mt={[8, 16]}>
        {data?.entry?.callToActions?.map((link) => {
          return (
            <GridItem key={link.uid} colSpan={[12, 12, 6, 4]} colStart={1}>
              <NavigationListLink
                colorTheme="orange"
                href={link.ctaLink.url}
                title={link.ctaLink.text}
                description={link.description}
              ></NavigationListLink>
            </GridItem>
          )
        })}
      </GridContainer>
      <GridContainer my={24} rowGap={12}>
        {data?.entry?.articles.map((item) => {
          return item.entry?.map((entry) => (
            <GridItem key={entry.uid} colSpan={[6, 4, 3]} colStart={[null]}>
              {entry.typeHandle === 'articles' || entry.typeHandle === 'pages' ? (
                <ArticleCard {...entry} />
              ) : (
                <LinkBox as="article">
                  <Flex backgroundColor={'blue.100'} flexDirection="column" gap={2} p={6}>
                    <Text fontSize={['xs']} fontWeight="medium" color={'gray.600'}>
                      <Date relative>{entry.postDate}</Date>
                    </Text>
                    <RouterLink href={`/${entry.uri}`} passHref legacyBehavior>
                      <LinkOverlay
                        fontWeight={'medium'}
                        fontSize={['md', 'xl']}
                        lineHeight="shorter"
                        _hover={{ textDecoration: 'underline' }}
                      >
                        {entry.title}
                      </LinkOverlay>
                    </RouterLink>
                    <Text fontSize={['sm', 'md']}>{entry.intro}</Text>
                  </Flex>
                </LinkBox>
              )}
            </GridItem>
          ))
        })}
      </GridContainer>
      <GridContainer rowGap={[4, 8]} mt={[8, 16]}>
        {data?.mainMenu?.services?.map((service) => {
          return (
            <GridItem key={service.uid} colSpan={[12, 12, 6, 4]} colStart={1}>
              <NavigationListLink
                colorTheme="gray"
                href={service.uri}
                title={service.title}
                description={service.intro}
                icon={service.navigationalSymbol[0]?.url}
              ></NavigationListLink>
            </GridItem>
          )
        })}
      </GridContainer>
      <Box height={[8, 10]}></Box>
      <BlocksPageBuilder blocks={data.entry?.pageBuilder} entryType={'frontpage'} />
    </BaseLayout>
  )
}

export async function getStaticProps({ locale }) {
  const site = getSiteHandle(locale)

  const data = await fetcher(GET_FRONT_PAGE, null, {
    site,
  })

  return { props: { data, site }, revalidate: 600 }
}

export type Props = {
  site: string
  data: {
    entry: FrontPageType
    entries: [Entry]
    singleEntries: [Entry]
    mainMenu?: MainMenu
    footerMenu?: FooterMenu
    alerts?: any
    contactInfo?: ContactInfo
  }
}

export default FrontPage
