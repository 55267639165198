import { Box } from '@chakra-ui/react'

const HeadingWithColor = ({ children }: Props) => {
  return (
    <Box
      fontFamily="heading"
      fontSize={['3xl', '4xl', '5xl']}
      lineHeight={'shorter'}
      fontWeight="medium-display"
      as="h1"
      // dangerouslySetInnerHTML={{
      //   __html: filterXSS(children, xssOptions),
      // }}
    >
      {children}
    </Box>
  )
}

type Props = { children: string }

export default HeadingWithColor
